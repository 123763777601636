import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import SolutionText from '../components/solutionText'
import SolutionTextImage from '../components/solutionTextImage'
import SolutionCards from '../components/solutionCards'
import SolutionBulletsHorizontal from '../components/solutionBulletsHorizontal'
import SolutionTextImageBelow from '../components/solutionTextImageBelow'
import SolutionBulletsImage from '../components/solutionBulletsImage'
import CardIcons from '../components/cardIcons.js'
import AltSection from '../components/columnAlternating'
import Card from '../components/card'
import CarouselQuote from '../components/carouselQuote'  

library.add(fat)

const About = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara }}
                />

                {post.html != null && (
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                )}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section && 
        <>
        {post.frontmatter.section.map((sections, index) => (
          <>

            {sections.type === 'text' && (
              <SolutionText key={index} data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
            )}   

            {sections.type === 'cards' && (
              <SolutionCards key={index} data={sections} sectionClass={sections.class} />
            )}

            {sections.type === 'bullets horizontal' && (
              <SolutionBulletsHorizontal key={index} data={sections} sectionClass={sections.class} sectionCenter={sections.center} />
            )}
            {sections.type === 'text with image below' && (
              <SolutionTextImageBelow key={index} data={sections} sectionClass={sections.class} />
            )}

            {sections.type === 'text and image' && (
              <SolutionTextImage key={index} data={sections} sectionClass={sections.class} placement={sections.placement} />
            )}

            {sections.type === 'bullets and image' && (
              <SolutionBulletsImage key={index} data={sections} sectionClass={sections.class} placement={sections.placement} />
            )}
            
          </>
        ))}
        </>
        }

        {post.frontmatter.bragroll && (
          <section className="bg-white">
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                  {post.frontmatter.bragroll.title}
                </h2>
                {post.frontmatter.bragroll.card && (
                  <MDBRow>
                    <>
                      {post.frontmatter.bragroll.card.map(cards => {
                        return (
                          <CardIcons
                            collg="4"
                            colmd="6"
                            title={cards.title}
                            subtitle={cards.subtitle}
                            image={cards.image.childImageSharp.gatsbyImageData}
                            alt={cards.alttext}
                          />
                        )
                      })}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}    


        {post.frontmatter.quote && 
          <CarouselQuote data={post.frontmatter.quote} />
        }

        <section className="bg-white">
          <MDBContainer>
            <h2 className="font-alt font-w-700 letter-spacing-1 pb-5 mt-xl-0 title-xs-medium title-large" >
            How customers are using Macro 4 solutions
            </h2>
              {post.frontmatter.customer.map((customers, index) => {
                return (
                  <AltSection
                  key={index}
                  title={customers.title}
                  subtitle={customers.subtitle}
                  description={customers.description}
                  image={customers.image.childImageSharp.gatsbyImageData}
                  alt={customers.alttext}
                  placement={customers.placement}
                  link={customers.btnlink}
                  linktext={customers.linktext}
                  />
                )
              })}
          </MDBContainer>
        </section>

        {post.frontmatter.helpfulresources && (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {' '}
                  {post.frontmatter.helpfulresources.title}{' '}
                </h2>
                <p className="text-medium">
                  {post.frontmatter.helpfulresources.subtitle}
                </p>
                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    <>
                      {post.frontmatter.helpfulresources.helpfulres.map(
                        (helpfulres) => {
                          return (
                            <Card
                              collg="4"
                              colmd="6"
                              height="7.5rem"
                              title={helpfulres.title}
                              subtitle={helpfulres.subtitle}
                              description={helpfulres.description}
                              image={ helpfulres.image.childImageSharp.gatsbyImageData }
                              alttext={helpfulres.alttext}
                              placement={helpfulres.placement}
                              link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                              titleclass="title-small"
                              descriptionClass="text-card-small py-2"
                            />
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}

      </main>
    </Layout>
  )
}
export default About

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {   
    markdownRemark(frontmatter: { name: { eq: "About" } }) {
      frontmatter {
        meta {
          title
          description 
        }        
        name
        title
        subtitle
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        section {
          title
          subtitle
          intro
          description
          class
          center
          type
          link
          linktext
          alttext
          placement
          document {
            publicURL
          }
          image {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
          feature {
            title
            subtitle
            icon
            description
            link
            linktext
            alttext
            colour
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
        bragroll {
          title
          card {
            title
            subtitle
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
          }
        }
        quote {
          title
          strapline
          author
        }        
        customer {
          title
          subtitle
          placement
          description
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, quality: 90)
            }
          }
          alttext
          btnlink
          linktext
        }
        helpfulresources {
          title
          subtitle
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }                        
      }
      html
    }
  }
`